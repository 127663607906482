import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CroppedLogo from './Images/CroppedLogo.png'
import { Container } from 'react-bootstrap'

function App() {
    return (
        <section>
            <div className='home'>
                {/* <NavigationHeader /> */}
                <Container>
                    <img src={CroppedLogo} width='50%' alt='logo'/>
                    <h1>Coming Soon!</h1>
                </Container>
            </div>
        </section>
    );
}

export default App;
